<template>
  <VContainer fluid>
    <DialogWrapper
      v-model="openUserForm"
      fullscreen
      @click:outside="closeModal('openUserForm')"
    >
      <UserForm
        v-if="openUserForm"
        :edit="edit"
        :edit-entity="activeEntity"
        @save="handleSave"
        @close="closeModal('openUserForm')"
      />
    </DialogWrapper>

    <DialogWrapper
      v-model="deleteDialog"
      width="396"
      content-class="tt-card"
      hide-overlay
      @click:outside="deleteDialog = false"
    >
      <DeleteForm
        @delete="handleDelete"
        @cancel="deleteDialog = false"
      >
        <h3 class="delete-form__title">
          Удалить пользователя?
        </h3>
        <p class="mb-1">
          Пользователь и все даные о нем будут удалены.
        </p>
      </DeleteForm>
    </DialogWrapper>

    <VRow>
      <VCol>
        <h1
          class="tt-text-headline-1"
          data-test="users-h1"
        >
          Пользователи
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTDataTable
          :items="users"
          :headers="headers"
          :loading="loading"
          :items-per-page="-1"
          extra-tall
          hide-default-footer
          data-test="table-location"
        >
          <template #item.last_name="{item}">
            <div data-test="users-last-name">
              {{ item.last_name }} {{ item.first_name }}
            </div>
          </template>
          <template #item.role="{item}">
            <p data-test="users-role">
              {{ getRoles(item.role) }}
            </p>
          </template>
          <template #item.created="{item}">
            <div class="d-flex align-start justify-space-between">
              <p data-test="users-created">
                {{ formatDate(item.created) }}
              </p>
              <div>
                <TTBtn
                  v-if="item.action.indexOf('EDIT') !== -1"
                  fab
                  depressed
                  class="mr-2"
                  width="24"
                  height="24"
                  color="transparent tt-ghost--text"
                  :ripple="false"
                  :data-test="`button-edit-${item.id}`"
                  @click="openModal('openUserForm',item)"
                >
                  <VIcon size="19">
                    fal fa-pencil
                  </VIcon>
                </TTBtn>
                <TTBtn
                  v-if="item.action.indexOf('DELETE') !== -1"
                  fab
                  depressed
                  width="24"
                  height="24"
                  color="transparent tt-ghost--text"
                  :ripple="false"
                  :data-test="`button-delete-${item.id}`"
                  @click.stop="openDelete(item)"
                >
                  <VIcon size="19">
                    fal fa-trash-alt
                  </VIcon>
                </TTBtn>
              </div>
            </div>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import * as snamiApi from '@/services/backend/snami';
import UserForm from '@/components/forms/UserForm.vue';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import { formatDate } from '@/utils';
import { dictionaryKeys } from '@/constants';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

export default {
  name: 'Users',
  components: { DialogWrapper, DeleteForm, UserForm },
  data() {
    return {
      loading: true,
      openUserForm: false,
      deleteDialog: false,
      users: [],
      rolesMap: {},
      toDelete: 0,
      edit: false,
      activeEntity: {},
      headersSrc: [
        {
          text: 'Имя',
          value: 'last_name',
        },
        {
          text: 'Логин',
          value: 'login',
          access: 'user_manage',
        },
        {
          text: 'Роли',
          value: 'role',
        },
        {
          text: 'Создан',
          value: 'created',
          width: '205px',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('acl', ['can']),
    headers() {
      return this.headersSrc.filter((item) => {
        if (item.value === 'login') {
          return this.can('user_manage');
        }
        return true;
      });
    },
  },
  async created() {
    this.loading = true;
    try {
      const [
        { data: { data: userList } },
        { data: { data: roles } },
      ] = await Promise.all([
        snamiApi.getUserList(),
        this.$repositories.customer.getCustomerDictionary({ data: { key: [dictionaryKeys.role] } }),
      ]);
      this.users = userList;
      roles.role.forEach((item) => {
        this.rolesMap[item.name] = item.caption;
      });
    } catch (e) {
      console.warn(e);
    }
    this.loading = false;
  },
  mounted() {
    this.$root.$on('openUserForm', this.createUser);
  },
  beforeDestroy() {
    this.$root.$off('openUserForm', this.createUser);
  },
  methods: {
    createUser() {
      this.openModal('openUserForm');
    },
    formatDate,
    getRoles(roles) {
      if (roles && Array.isArray(roles)) {
        return roles.map((item) => this.rolesMap[item])
          .join(', ');
      }
      return '';
    },
    handleDelete() {
      snamiApi.deleteUser(this.toDelete).then(() => {
        this.deleteDialog = false;
        this.toDelete = 0;
        this.loading = true;
        return snamiApi.getUserList();
      }).then((r) => {
        const { data } = r.data;
        this.users = data;
      }).catch((e) => console.warn(e))
        .finally(() => { this.loading = false; });
    },
    openDelete(item) {
      this.deleteDialog = true;
      this.toDelete = item.id;
    },
    handleSave() {
      this.closeModal('openUserForm');
      this.loading = true;
      snamiApi.getUserList().then((r) => {
        const { data } = r.data;
        this.users = data;
      }).catch((e) => console.warn(e)).finally(() => { this.loading = false; });
    },
    openModal(name, item) {
      this[name] = true;
      if (item) {
        this.activeEntity = item;
        this.edit = true;
      }
    },
    closeModal(name) {
      this[name] = false;
      this.edit = false;
      this.activeEntity = {};
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
