<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <VRow>
              <VCol>
                <h1
                  data-test="user-form-title"
                  class="tt-text-headline-1"
                >
                  {{ edit ? 'Редактирование' : 'Добавление нового' }} пользователя
                </h1>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="4">
                <TTTextField
                  large
                  label="Фамилия"
                  placeholder="Иванов"
                  :value="entity.last_name"
                  :error="validation('last_name').status"
                  :error-messages="validation('last_name').value"
                  data-test="user-form-input-last-name"
                  @input="handleChange('last_name', $event)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="4">
                <TTTextField
                  large
                  label="Имя"
                  placeholder="Иван"
                  :value="entity.first_name"
                  :error="validation('first_name').status"
                  :error-messages="validation('first_name').value"
                  data-test="user-form-input-first-name"
                  @input="handleChange('first_name',$event)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="4">
                <TTTextField
                  large
                  label="Отчество"
                  placeholder="Иванович"
                  :value="entity.middle_name"
                  :error="validation('middle_name').status"
                  :error-messages="validation('middle_name').value"
                  data-test="user-form-input-middle-name"
                  @input="handleChange('middle_name',$event)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="4">
                <TTTextField
                  large
                  label="Email"
                  placeholder="example@yourcompany.com"
                  :value="entity.login"
                  :disabled="edit"
                  :error="validation('login').status"
                  :error-messages="validation('login').value"
                  data-test="user-form-input-login"
                  @input="handleChange('login',$event)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="4">
                <VCheckbox
                  v-for="role in roles"
                  :key="role.name"
                  v-model="entity.role"
                  class="mt-0"
                  data-test="user-form-checkbox-role"
                  hide-details="auto"
                  :value="role.name"
                  :ripple="false"
                >
                  <template #label>
                    <span data-test="user-form-checkbox-label-role">{{ role.caption }}</span>
                  </template>
                </VCheckbox>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <div class="d-flex">
                  <TTBtn
                    large
                    color="tt-secondary"
                    depressed
                    class="mr-6"
                    data-test="user-form-button-cancel"
                    @click="handleClose"
                  >
                    Отменить
                  </TTBtn>
                  <TTBtn
                    depressed
                    large
                    data-test="user-form-button-save"
                    @click="save"
                  >
                    Сохранить
                  </TTBtn>
                </div>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              data-test="user-form-button-close"
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              @click="handleClose"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div
              class="tt-text-caption tt-black--text text--lighten-2"
              data-test="user-form-label-close"
            >
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { handleError, validation, watchValidationStatus } from '@/services';
import * as snamiApi from '@/services/backend/snami';
import { dictionaryKeys } from '@/constants';

export default {
  name: 'UserForm',
  props: {
    editEntity: {
      type: Object,
      default: () => {
      },
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      validationStatus: [],
      rolesList: [],
      entity: {
        last_name: '',
        first_name: '',
        middle_name: '',
        login: '',
        role: [],
      },
    };
  },
  computed: {
    ...mapGetters('acl', ['userId']),
    roles() {
      return this.rolesList.filter((role) => role.selectable);
    },
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  async created() {
    const { data: { data: rolesList } } = await this.$repositories.customer
      .getCustomerDictionary({ data: { key: [dictionaryKeys.role] } });
    this.rolesList = rolesList.role;
  },
  mounted() {
    if (this.edit) {
      this.handleEntity();
    }
  },
  methods: {
    ...mapActions('acl', ['getUser']),
    validation,
    handleError,
    async save() {
      const data = { ...this.entity };
      const type = this.edit ? 'update' : 'create';
      try {
        await snamiApi.saveUser({
          data,
          type,
        });
        // при смене роли проверяю текущего юзера
        if (this.checkCurrentUser(data.id)) {
          await this.getUser();
        }
        this.$emit('save');
      } catch (error) {
        this.handleError(error);
      }
    },
    handleEntity() {
      this.entity = { ...this.entity, ...this.editEntity };
    },
    handleClose() {
      this.$emit('close');
    },
    handleChange(name, value, target) {
      this.entity[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    checkCurrentUser(id) {
      return this.userId === id;
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
